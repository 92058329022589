import React, { useState } from 'react';
import { db } from '../firebase';
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
import ReCAPTCHA from 'react-google-recaptcha';
import { toast } from 'react-toastify';

const ContactForm = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        company: '',
        message: ''
    });
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [captchaToken, setCaptchaToken] = useState(null);

    const validateEmail = (email) => {
        return email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);
    };

    const sanitizeInput = (input) => {
        return input.trim().replace(/[<>]/g, ''); // Elimina < y > para prevenir XSS básico
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleCaptchaChange = (token) => {
        setCaptchaToken(token);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        // Validaciones básicas
        if (!captchaToken) {
            toast.error('Por favor, completa el reCAPTCHA');
            setIsSubmitting(false);
            return;
        }

        if (!validateEmail(formData.email)) {
            toast.error('Por favor, introduce un email válido');
            setIsSubmitting(false);
            return;
        }

        if (formData.message.length < 10 || formData.message.length > 1000) {
            toast.error('El mensaje debe tener entre 10 y 1000 caracteres');
            setIsSubmitting(false);
            return;
        }

        // Prevenir múltiples envíos
        if (isSubmitting) return;
        setIsSubmitting(true);

        try {
            // Sanitizar datos
            const sanitizedData = {
                name: sanitizeInput(formData.name),
                email: sanitizeInput(formData.email),
                company: sanitizeInput(formData.company),
                message: sanitizeInput(formData.message),
                timestamp: serverTimestamp(),
                captchaToken, // Guardamos el token para verificación adicional si es necesario
                userAgent: navigator.userAgent,
                submittedAt: new Date().toISOString()
            };

            await addDoc(collection(db, 'contacts'), sanitizedData);
            
            // Limpiar formulario
            setFormData({
                name: '',
                email: '',
                company: '',
                message: ''
            });
            setCaptchaToken(null);
            
            // Resetear ReCAPTCHA
            if (window.grecaptcha) {
                window.grecaptcha.reset();
            }

            toast.success('¡Mensaje enviado con éxito!');
        } catch (error) {
            console.error('Error al enviar el formulario: ', error);
            toast.error('Hubo un error al enviar el formulario. Por favor, intenta nuevamente.');
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div className='max-w-screen-lg mx-auto p-4'>
            <h1 className="text-3xl font-bold mb-12 text-mainTitle text-center">CONTÁCTANOS</h1>
            <form onSubmit={handleSubmit} className="max-w-screen-md mx-auto p-4 bg-white rounded-lg shadow-lg">
                <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700" htmlFor="name">
                        Nombre
                    </label>
                    <input
                        type="text"
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        className="mt-1 p-2 w-full border border-gray-300 rounded-md"
                        required
                        maxLength={100}
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700" htmlFor="email">
                        Email
                    </label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        className="mt-1 p-2 w-full border border-gray-300 rounded-md"
                        required
                        maxLength={100}
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700" htmlFor="company">
                        Empresa (opcional)
                    </label>
                    <input
                        type="text"
                        id="company"
                        name="company"
                        value={formData.company}
                        onChange={handleChange}
                        className="mt-1 p-2 w-full border border-gray-300 rounded-md"
                        maxLength={100}
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700" htmlFor="message">
                        Mensaje
                    </label>
                    <textarea
                        id="message"
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                        className="mt-1 p-2 w-full border border-gray-300 rounded-md"
                        rows="4"
                        required
                        maxLength={1000}
                    />
                </div>
                <div className="mb-4">
                    <ReCAPTCHA
                        sitekey="6LdGm8gqAAAAAE9uoeCiqB2jNowYr0avYi0dQXnh"
                        onChange={handleCaptchaChange}
                    />
                </div>
                <button 
                    type="submit" 
                    className={`${
                        isSubmitting ? 'bg-gray-400' : 'bg-blue-500 hover:bg-blue-600'
                    } text-white py-2 px-4 rounded-md font-bold transition-colors`}
                    disabled={isSubmitting || !captchaToken}
                >
                    {isSubmitting ? 'Enviando...' : 'Enviar'}
                </button>
            </form>
        </div>
    );
};

export default ContactForm;