import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const testimonials = [
    {
        name: 'Alba Jimenez',
        company: 'Saludando',
        message: 'Trabajar con Neerutech ha sido una experiencia excelente. Nos desarrolló un sistema de gestión totalmente adaptado a nuestras necesidades, facilitando la administración de pagos SEPA, clientes y suscripciones en nuestro centro de pilates. La plataforma nos ha permitido optimizar procesos y llevar un control detallado de nuestros ingresos mensuales. ¡Totalmente recomendado!'
    },
    {
        name: 'Carmen Rodriguez',
        company: 'Psicóloga',
        message: 'Gracias a Neerutech, ahora tengo una página web profesional donde mis clientes pueden conocer mis servicios y leer mi blog. Antes no contaba con una plataforma para mostrar mi trabajo, pero ahora tengo una web funcional y fácil de gestionar, donde también pueden contactarme y agendar sesiones. Me encantó su compromiso con el proyecto y cómo supo reflejar mi identidad profesional en el diseño. Lo recomiendo totalmente a cualquier profesional que necesite una presencia en línea'
    },
    {
        name: 'Roberto Muñoz',
        company: 'Lobstar Restaurante',
        message: 'Queríamos una página web que reflejara la esencia de nuestro restaurante y que permitiera a nuestros clientes conocer nuestros servicios, locaciones y carta de manera clara y atractiva. Neerutech entendió nuestra visión y creó un sitio web moderno, intuitivo y optimizado. Desde que lo lanzamos, hemos notado un aumento en las reservas y en la interacción con nuestros clientes. Si buscas un profesional que realmente se involucre en tu proyecto, sin duda es la mejor opción.'
    }
];

const Testimonials = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        arrows: false
    };

    return (
        <div className='max-w-screen-lg mx-auto p-4'>
            <h1 className='text-3xl font-bold mb-12 text-mainTitle text-center'>TESTIMONIOS</h1>
            <Slider {...settings}>
                {testimonials.map((testimonial, index) => (
                <div key={index} className='p-4'>
                    <div className='bg-white rounded-xl shadow-md p-6'>
                    <p className='text-lg italic mb-4'>"{testimonial.message}"</p>
                    <p className='text-sm font-bold'>{testimonial.name}</p>
                    <p className='text-sm text-gray-600'>{testimonial.company}</p>
                    </div>
                </div>
                ))}
            </Slider>
        </div>
    );
};

export default Testimonials;