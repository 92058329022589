import React, {useEffect} from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { projects } from '../data/projects';
import parse from 'html-react-parser';
import { ArrowLeft } from 'lucide-react';

const ProjectDetail = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const project = projects.find((project) => project.id === id);


    useEffect(() => {
        if (project && (!project.content || project.content.length === 0)) {
            window.open(project.link, '_blank');
            navigate('/');
        }
    }, [project, navigate]);

    if (!project) {
        return <div>Proyecto no encontrado</div>;
    }

    return (
        <div className="max-w-screen-lg mx-auto p-4">
            <div className="flex items-center mb-4 cursor-pointer" onClick={() => navigate('/')}>
                <ArrowLeft 
                    className="text-blue-800 hover:text-blue-600 transition-colors" 
                    size={24} 
                />
                <span className="ml-2 text-blue-800 hover:text-blue-600 transition-colors font-bold">Volver</span>
            </div>
            <div className='text-center'>
                <h1 className="text-3xl font-bold mb-6 text-center py-8 text-blue-800">{project.detailTitle}</h1>
                {(project.link) && (
                    <button 
                        className='bg-backgroundButton hover:bg-hoverButton text-white py-2 px-4 rounded-lg mt-6 font-bold'
                        onClick={() => window.open(project.link, '_blank')}
                    >
                        Ver Página
                    </button>
                )}
            </div>                    
            {project.content.map((section, index) => {
                switch (section.type) {
                    case "text":
                        return (
                            <div key={index} className="md:p-4 p-4 text-center">
                                {section.title && (<h3 className="text-2xl text-blue-700 font-bold">{section.title}</h3>)}
                                {section.text.map((paragraph, idx) => (
                                    <p key={idx} className="mb-8 text-blue-700 text-lg">{parse(paragraph)}</p>
                                ))}
                            </div>
                        );
                    case "image":
                        return <img key={index} src={section.src} alt="" className="centered-image mb-2" />;
                    case "bullets":
                        return (
                        <ul key={index} className="list-disc list-inside my-8 text-left text-blue-700 text-lg">
                            {section.points.map((point, idx) => (
                            <li key={idx} className='my-4'>{parse(point)}</li>
                            ))}
                        </ul>
                        );
                    case "text-image":
                        return (
                            <div key={index} className="image-text mb-4 flex flex-col md:flex-row justify-start gap-6">
                                <div className="md:md:w-1/2 md:p-16 p-4 text-center">
                                    {section.title && (<h3 className="text-2xl text-blue-700 font-bold mb-12">{section.title}</h3>)}
                                    {section.text.map((paragraph, idx) => (
                                        <p key={idx} className="mb-8 text-blue-700 text-lg">{parse(paragraph)}</p>
                                    ))}
                                </div>
                                <img src={section.src} alt="" className="md:md:w-1/2 mb-4 md:mb-0 md:p-20 p-4" />
                                
                            </div>
                        );
                    case "image-text":
                        return (
                            <div key={index} className="image-text mb-4 flex flex-col md:flex-row justify-start gap-6">
                                <img src={section.src} alt="" className="md:md:w-1/2 mb-4 md:mb-0 md:p-20 -mt-80"  />
                                <div className="md:w-1/2 p-16 text-center">
                                    {section.title && (<h3 className="text-2xl text-blue-700 font-bold mb-12">{section.title}</h3>)}
                                    {section.text.map((paragraph, idx) => (
                                        <p key={idx} className="mb-8 text-blue-700 text-lg">{parse(paragraph)}</p>
                                    ))}
                                </div>
                            </div>
                        );
                    case "text-imageDown-imageRight":
                        return (
                            <div key={index} className="image-text mb-4 flex flex-col md:flex-row justify-start gap-6">
                                <div className="md:md:w-1/2 md:p-16 p-4 text-center flex flex-col justify-center items-center gap-6">
                                    <div className='w-full'>
                                        {section.title && (<h3 className="text-2xl text-blue-700 font-bold mb-12">{section.title}</h3>)}
                                        {section.text.map((paragraph, idx) => (
                                            <p key={idx} className="mb-8 text-blue-700 text-lg">{parse(paragraph)}</p>
                                        ))}
                                    </div>
                                    <div className='text-center'>
                                        <img src={section.src} alt="" />        
                                    </div>
                                </div>
                                <div className='md:md:w-1/2 mb-4 md:mb-0 md:p-12'>
                                    <img src={section.src2} alt="" className="p-4" />
                                </div>
                                
                                
                            </div>
                        );
                    default:
                        return null;
                }
            })}
            <div className='text-center'>
                <button onClick={() => navigate(-1)} className="bg-blue-500 text-white py-2 px-4 rounded my-16 font-bold  text-center">
                    Volver a Proyectos
                </button>
            </div>
            
        </div>
    );
};

export default ProjectDetail;