import React from 'react';
import { Link } from 'react-router-dom';

const ProjectCard = ({ project }) => {
    return (
        <div className="bg-white border p-4 rounded-lg shadow-lg hover:shadow-2xl transition-shadow duration-300">
            <div className='flex flex-col justify-between gap-4 h-full'>
                <div>
                    <img 
                        src={project.image} 
                        alt={project.title} 
                        className="w-full h-72 lg:h-80 object-cover object-top rounded-t-lg mb-4" 
                    />
                </div>
                <div>
                    <h2 className="text-xl font-bold">{project.title}</h2>
                    <p className="text-gray-600 mt-2">{project.description}</p>
                </div>
                <div className="flex flex-wrap gap-2 mt-3">
                    {project.technologies.map((tech, index) => (
                    <span 
                        key={index} 
                        className="bg-blue-100 text-blue-800 text-sm font-semibold px-2.5 py-0.5 rounded"
                    >
                        {tech}
                    </span>
                    ))}
                </div>
                <div className='align-bottom'>
                    <Link to={`/project/${project.id}`} className="inline-block mt-4 px-4 py-2 bg-backgroundButton text-white  hover:bg-hoverButton rounded-lg">
                        Ver Proyecto
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default ProjectCard;
