import React from 'react';

const Hero = () => {
    const scrollToContact = () => {
        const contactSection = document.getElementById('contactanos');
        if (contactSection) {
            contactSection.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <div className='flex flex-col md:flex-row justify-between items-center max-w-screen-lg mx-auto p-8'>
            <div className='w-full md:w-[50%] pb-10 md:pb-20'>
                <h1 className='font-bold text-2xl md:text-4xl mb-4 text-white'>IMPULSA TU NEGOCIO CON SOLUCIONES DIGITALES PERSONALIZADAS</h1>
                <p className='text-sm md:text-base text-white'>Somos desarrolladores web especializados en creación de <strong>páginas web</strong> modernas y <strong>sistemas informáticos</strong> para la optimización digital de tu negocio. </p>
                <button 
                    className='bg-backgroundButton hover:bg-hoverButton text-white py-2 px-4 rounded-lg mt-6 font-bold'
                    onClick={scrollToContact}
                >
                    Contáctanos
                </button>
            </div>
            <div className='w-full md:w-[50%]'>
                <img className='w-full md:w-auto' src="/assets/images/hero.png" alt="Hero" />
            </div>            
        </div>
    );
};

export default Hero;