import React from 'react';
// import { ChevronsRight  } from 'lucide-react';

const Services = () => {
    return (
        <div className='max-w-screen-lg mx-auto p-4'>
            <h1 className='text-3xl font-bold mb-12 text-mainTitle text-center'>SERVICIOS</h1>
            <div className='flex flex-col md:flex-row justify-between items-center gap-8'>
                <div className='bg-white rounded-xl flex flex-col w-full md:w-[33%] p-4 text-center align-top'>
                    <img className='md:w-auto p-4' src="/assets/images/servicio_web.svg" alt="Servicios Páginas Web" />
                    <h2 className='text-xl font-bold mt-2 py-2'>Páginas Web</h2>
                    <p className='text-sm'>
                        Diseño y Desarrollo de Páginas Web para una sólida presencia online.
                    </p>
                </div>
                <div className='bg-white rounded-xl  flex flex-col w-full md:w-[33%] p-4 text-center'>
                    <img className='md:w-auto' src="/assets/images/servicio_desarrollo.svg" alt="Servicios Desarrollo Sistemas Informáticos" />
                    <h2 className='text-xl font-bold mt-4 py-2'>Sistemas Informáticos</h2>
                    <p className='text-sm'>
                        Desarrollo de programas informáticos para impulsar tu negocio.
                    </p>
                </div>
                <div className='bg-white rounded-xl flex flex-col w-full md:w-[33%] p-4 text-center'>
                    <img className='md:w-auto' src="/assets/images/servicio_analitica.svg" alt="Servicios de Analítica Web" />
                    <h2 className='text-xl font-bold mt-4 py-2'>Analítica Web</h2>
                    <p className='text-sm'>
                        Herramientas de análisis para potenciar el rendimiento de tu página web.
                    </p>
                </div>
            </div>
            <div className='w-full pt-8 text-center'>
                <button 
                    className='bg-backgroundButton hover:bg-hoverButton text-white py-2 px-8 rounded-lg mt-6 font-bold'
                    onClick={() => window.open('https://www.neerutech.com/es/services', '_blank')}
                >
                    Ver Más
                </button>
            </div>
            
            {/* <div className='w-full pt-8 text-center'>
                <button 
                        className='bg-red-700 text-white py-2 px-4 shadow-md rounded-lg mt-6 font-bold flex items-center justify-center mx-auto'
                >
                    Más Información <ChevronsRight size={24} className='ml-2' />
                </button>
            </div> */}
        </div>
    );
};

export default Services;